
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCommonDiet} from "@/models/app/common/diet";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Search} from "@element-plus/icons-vue";

@Options({
  props: [
    "diet",
  ],
  components: {
    ElDialog,
    ElButton,
    Search,
  },
})
export default class AppPlayerResourcesDietsListFormLookupIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  diet: AppCommonDiet = new AppCommonDiet();

  get dietTitle(): string {
    let type = "";

    switch (this.diet.content.type) {
      case "breakfast":
        type = "Doručak";
        break;
      case "snack":
        type = "Međuobrok";
        break;
      case "lunch":
        type = "Ručak";
        break;
      default:
        type = "Večera";
    }
    return `${this.diet.content.name} - ${type}`;
  }
}
