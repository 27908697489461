
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import PlayerNavigationVue from "@/components/player/navigation.vue";
import PlayerResourcesNavigationVue from "@/components/player/resources/navigation.vue";
import AppPlayerResourcesDietsListIndexVue from "@/views/app/player/resources/diets/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    PlayerNavigationVue,
    PlayerResourcesNavigationVue,
    AppPlayerResourcesDietsListIndexVue,
  },
})
export default class AppPlayerResourcesDietsIndexVue extends Vue {}
